const urlServer = "/push-notifications/register";

const firebaseConfig = {
    apiKey: "AIzaSyAgxK65IgqZYB1uS6C772SxDAllfGN2m1A",
    authDomain: "pct-web-fbef6.firebaseapp.com",
    projectId: "pct-web-fbef6",
    storageBucket: "pct-web-fbef6.appspot.com",
    messagingSenderId: "177456439660",
    appId: "1:177456439660:web:d6751f2a39b77964291349"
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

async function post(url, data) {
    try {
        const response = await fetch(url, {
            method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(data)
        });
        return await response.json();
    } catch (error) {
        console.error("Error en POST:", error);
    }
}

function checkCookieName(name) {
    return document.cookie.includes(`${name}=`);
}

function isMobile() {
    return window.innerWidth <= 768;
}

function handleScroll() {
    const scrollThreshold = 400;
    const notification = document.querySelector('.notification');
    if (isMobile() && window.scrollY >= scrollThreshold) {
        notification.classList.add('active');
    }
}

function handleSubscription() {
    messaging.requestPermission()
        .then(() => messaging.getToken())
        .then(async (token) => {
            const response = await post(urlServer, {push_id: token});
            console.log(response.message);
        })
        .catch((error) => console.error("Error al obtener token:", error));
}

function handleCancel(notification) {
    notification.classList.remove('active');
    document.cookie = "allow-notifications=no; max-age=86400";
}

function setupNotifications() {
    const btnNotification = document.querySelector('.notification__subscribe');
    const btnCancel = document.querySelector('.notification__cancel');
    const notification = document.querySelector('.notification');

    navigator.permissions.query({name: 'notifications'}).then((permission) => {
        if (permission.state === 'granted' || checkCookieName('allow-notifications')) {
            notification.classList.remove('active');
            return;
        }

        if (!isMobile()) {
            notification.classList.add('active');
        }

        btnNotification.addEventListener('click', () => {
            notification.classList.remove('active');
            handleSubscription();
        });

        btnCancel.addEventListener('click', () => handleCancel(notification));
    });
}

document.addEventListener('DOMContentLoaded', () => {
    setupNotifications();
    window.addEventListener('scroll', handleScroll);
});
